<template>
  <div class="m-0 p-3">
    <div class="mb-3 d-flex justify-content-between align-items-center">
      <h1 class="m-0">List of Events</h1>
      <router-link class="ml-auto mr-1"
                   :to="{ name: 'admin.event.location'}">
        <base-button class="white-outline-button button-font title-btn-padding">
          Location
        </base-button>
      </router-link>
      <router-link class="ml-2" :to="{ name: 'admin.event.store'}">
        <base-button class="btn-secondary-action button-font title-btn-padding">
          Add New
        </base-button>
      </router-link>
    </div>
    <div class="mt-2 cardStyle" v-loading="loaders.checkboxButton || loaders.events">
      <div v-if="selectedRows.length" id="select-option-container" class="mt-5 mb-1 ml-4">
        <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                     @click="promptMultiRemove"
                     :disabled="loaders.removeModal">
          Delete
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :disabled="loaders.checkboxButton"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :disabled="loaders.checkboxButton"
        >Deactivate
        </base-button>
      </div>
      <div v-else class="row mt-4 mx-1 px-2 col-xl-11">
        <div class="col">
          <label for="" class="form-control-label">Month / Year</label>
          <vue-monthly-picker
            class="super-admin-event-month-picker"
            @selected="fetchEvents"
            dateFormat="MMMM YYYY"
            :clearOption="false"
            placeHolder="Pick a month"
            :monthLabels="monthPickerLabels"
            selectedBackgroundColor="#832C44"
            v-model="params.date">
          </vue-monthly-picker>
        </div>
        <div class="col">
          <base-select
            label="Status"
            v-model="params.status"
            @change="fetchEvents"
            :options="dropdownsOptions.status"
            custom-clearable
            class="filter-search mr-2"
          />
        </div>
        <div class="col">
          <base-select
            label="Weight Loss Type"
            v-model="params.weight_loss_type"
            @change="fetchEvents"
            :options="dropdownsOptions.weightLoss"
            custom-clearable
            class="filter-search mr-2"
          />
        </div>
        <div class="col">
          <base-select
            label="Venue Type"
            v-model="params.venue_type"
            @change="fetchEvents"
            :options="dropdownsOptions.venue"
            custom-clearable
            class="filter-search mr-2"
          />
        </div>
        <div class="col">
          <base-select
            label="Event Type"
            v-model="params.type"
            @change="fetchEvents"
            :options="dropdownsOptions.type"
            custom-clearable
            class="filter-search mr-2"
          />
        </div>
        <div class="col">
          <base-select
            label="Location"
            v-model="params.location"
            @change="fetchEvents"
            :options="dropdownsOptions.locations"
            custom-clearable
            class="filter-search mr-2"
          />
        </div>
      </div>
      <el-table
        ref="multipleTable"
        header-row-class-name="thead-light"
        class="show-hidden-scrollbar event-list-table w-100"
        :data="response.events.data"
        :row-class-name="tableRowClassName"
        @selection-change="eventsMultiSelect">
        <el-table-column prop="selected" type="selection" min-width="130">
        </el-table-column>
        <el-table-column label="EVENT NAME" prop="event_title" min-width="200">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ scope.row.event_title }}</div>
          </template>
        </el-table-column>
        <el-table-column label="LOCATION" prop="location_id" min-width="200">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ getLocation(scope.row) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="WEIGHT LOSS TYPE" prop="weight_loss_type" width="150">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ getWeightLossType(scope.row.weight_loss_type) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="VENUE TYPE" prop="venue_type" width="150">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ getVenueType(scope.row.venue_type) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="EVENT TYPE" prop="type" width="150">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ getEventType(scope.row.type) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="DATE" prop="date" width="150">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ scope.row.date | getFormattedDate }}</div>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" width="120">
          <template slot-scope="scope">
              <span :class="['status',getStatusClass(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatusClass(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" width="200">
          <div slot-scope="{ $index, row }" class="d-flex">
            <el-tooltip content="Show Event" placement="top">
              <router-link :to="{name: 'admin.event.show', params: {id: row.id}}">
                <img class="eye-icon mr-2" src="/img/eye.svg" alt="">
              </router-link>
            </el-tooltip>
            <el-tooltip content="Copy Event" placement="top">
              <router-link :to="{name: 'admin.event.store', params: {id: row.id, copyEventFlag: true}}">
                <img class="pointer mr-2" src="/img/icons/buttons/copy.svg" alt="">
              </router-link>
            </el-tooltip>
            <el-tooltip content="Edit Event" placement="top">
              <router-link :to="{name: 'admin.event.edit', params: {id: row.id}}">
                <img class="pointer mr-2" src="/img/icons/buttons/edit.svg" alt="">
              </router-link>
            </el-tooltip>
            <el-tooltip content="Delete Event" placement="top">
              <img
                class="pointer"
                @click="promptRemove(row)"
                src="/img/icons/buttons/delete.svg"
                alt="Delete"
              />
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
      <div class="pt-5 w-100 d-flex justify-content-end">
        <custom-pagination
          class="pr-5"
          :total="response.events.total"
          v-model="response.events.current_page"
          :per-page="response.events.per_page"
          @input="changePage"
        >
        </custom-pagination>
      </div>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeEvent"
        @onRemoveMultiple="removeEvents"
      />
    </div>
  </div>
</template>

<script>
import {Option, Select, Table, TableColumn} from "element-ui";
import {BasePagination} from "@/components";
import TableWithActionsAndPagination from "@/components/Tables/TableWithActionsAndPagination";
import CustomPagination from "@/views/Components/Pagination/CustomPagination";
import moment from "moment";

const RemoveModal = () => import("@/components/Modals/RemoveModal.vue");
import {constants} from "@/util/constants";

export default {
  name: "Events",
  components: {
    CustomPagination,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    CategoryTemplate: TableWithActionsAndPagination,
    RemoveModal,
  },
  data() {
    return {
      loaders: {
        events: false,
        removeModal: false,
        checkboxButton: false,
        locations: false
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      response: {
        events: {
          data: [],
          current_page: 1,
          per_page: 10,
          total: 0,
        },
        locations: [],
      },
      params: {
        date: '',
        status: '',
        type: '',
        location: '',
        weight_loss_type: '',
        venue_type: '',
        page: 1
      },
      venueTypes: {
        '1': 'In-Person',
        '2': 'Virtual',
      },
      weightLossTypes: {
        '1': 'Surgical',
        '2': 'Non-Surgical',
      },
      eventTypes: {
        '1': 'Fixed Date',
        '2': 'Weekly',
      },
      monthPickerLabels: constants.monthPickerLabels,
      dropdownsOptions: {
        status: [
          {label: 'All', value: ''},
          {label: 'Active', value: 1,},
          {label: 'Inactive', value: 0,}
        ],
        weightLoss: [
          {label: 'All', value: ''},
          {label: 'Surgical', value: '1',},
          {label: 'Non-Surgical', value: '2',}
        ],
        venue: [
          {label: 'All', value: ''},
          {label: 'In-Person', value: '1',},
          {label: 'Virtual', value: '2',}
        ],
        type: [
          {label: 'All', value: ''},
          {label: 'Fixed Date', value: 1,},
          {label: 'Weekly', value: 2,}
        ],
        locations: [
          {label: 'Other', value: ''},
        ],
      },
    }
  },
  computed: {
    /**
     * Extract selected ids
     * @returns {*[]}
     */
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },

    /**
     * Get date object
     * @returns {{month: number, year: number}}
     */
    getDateObject() {
      const date = new Date(this.params.date)
      return {year: date.getFullYear(), month: date.getMonth() + 1}
    }
  },
  filters: {
    /**
     * Reformat created date
     * @param date
     * @returns {string}
     */
    getFormattedDate: function (date) {
      return moment.utc(date).local().format('MMM DD, YYYY')
    },
  },
  mounted() {
    this.getLocations()
    this.params.date = new Date(Date.now()).toISOString()
    this.fetchEvents()
  },
  methods: {
    /**
     * Get Location
     * @param row
     */
    getLocation(row) {
      if (row.location) {
        return row.location
      }
      if (row.location == null) {
        return 'Other'
      } else
        return row.admin_location?.name ?? ''
    },

    /**
     * Get Weight Loss Type
     * @param weightLossType
     * @returns {string}
     */
    getWeightLossType(weightLossType) {
      return this.weightLossTypes[weightLossType] ?? 'All'
    },

    /**
     * Get Venue Type
     * @param venueType
     */
    getVenueType(venueType) {
      return this.venueTypes[venueType] ?? 'All'
    },

    /**
     * Get Event type
     * @param type
     * @returns {string}
     */
    getEventType(type) {
      return this.eventTypes[type] ?? ''
    },

    /**
     * Remove Event
     * @param id
     */
    removeEvent(id) {
      this.deleteEvent(id)
    },

    /**
     * Remove Multiple Event
     */
    removeEvents() {
      this.deleteEvent(this.removeId)
    },

    /**
     * Handle Active Button event
     */
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    /**
     * Handle DeActive Button event
     */
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    /**
     * Prompt remove
     * @param row
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove this Event.'
      this.removeId = row.id
    },

    /**
     * Prompt Multi-remove
     */
    promptMultiRemove() {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove these Events.'
      this.removeId = this.extractSelectedIds
    },

    /**
     * Get Table row class name
     * @param row
     * @returns {string}
     */
    tableRowClassName({row}) {
      if (row.date < moment().format('YYYY-MM-DD')) {
        return 'before-date'
      }
      return '';
    },

    /**
     * Get status class
     * @param status
     * @returns {string}
     */
    getStatusClass(status) {
      return status === 0 ? 'Inactive-Status' : 'Active-Status'
    },

    /**
     * Get status as a string
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Handle multi-select action
     * @param selectedRows
     */
    eventsMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    /**
     * Change Page
     * @param page
     */
    changePage(page) {
      this.params.page = page
      this.fetchEvents(false)
    },

    /**
     * Get Event Listing
     * @param resetPage
     */
    fetchEvents(resetPage = true) {
      let vm = this

      if (resetPage) {
        this.params.page = 1
      }

      vm.loaders.events = true
      const config = {
        params: Object.assign({}, this.params)
      }
      config.params.date = this.getDateObject
      vm.$store.dispatch('EventModule/_getEvents', config)
        .then(response => {
          vm.response.events = response.data.data
        })
        .catch(error => {
            if (!axios.isCancel(error)) {
              const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
              vm.$notify.error({
                title: 'Events',
                message: message
              })
            }
        })
      .finally(() => {
        vm.loaders.events = false
      })
    },

    /**
     * Delete Event(s)
     * @param data
     */
    deleteEvent(data) {
      let vm = this
      vm.loaders.removeModal = true
      const payload = {
        ids: Array.isArray(data) ? data : [data],
      }

      vm.$store.dispatch('EventModule/_deleteEvent', payload)
      .then(response => {
        vm.$notify.success({
          title: 'Success',
          message: 'Event Deleted Successfully!'
        })
        vm.removeId = []
        vm.fetchEvents()
        vm.$store.commit("hideRemoveModal")
      })
      .catch(error => {
        const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Delete',
          message: message
        })
      })
      .finally(() => {
        vm.loaders.removeModal = false
      })
    },

    /**
     * Update Status of Event(s)
     * @param ids
     * @param status
     */
    statusUpdateRequest(ids, status) {
      let vm = this
      vm.loaders.checkboxButton = true

      const payload = {
        ids: Array.isArray(ids) ? ids : [ids],
        status: status
      }

      vm.$store.dispatch('EventModule/_updateEventStatus', payload)
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Event(s) Status Updated Successfully!'
          })
          vm.removeId = []
          vm.fetchEvents()
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Status',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.checkboxButton = false
        })
    },

    /**
     * Get Locations
     */
    getLocations() {
      let vm = this
      vm.$store.dispatch('LocationModule/_getLocations', {})
        .then(response => {
          vm.response.locations = response.data.data.locations
          vm.prepareLocationsData(response.data.data.locations)
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Event Locations',
            message: message
          })
        })
    },

    /**
     * Prepare Locations Data
     * @param locations
     */
    prepareLocationsData(locations) {
      let vm = this
      vm.dropdownsOptions.locations = []

      vm.dropdownsOptions.locations.push({
        label: 'All', value: ''
      })

      locations.forEach(location => {
        vm.dropdownsOptions.locations.push({
          label: location.name,
          value: location.id,
        })
      })

      vm.dropdownsOptions.locations.push({
        label: 'Other', value: '0'
      })
    },
  },
}
</script>

<style scoped lang="scss">
/* Explicitly showing scroll bar even if gets hide */
.show-hidden-scrollbar > > > .el-table__body-wrapper {
  overflow: auto !important;
}

.cardStyle {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #00000000;;
}

.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.indicator {
  height: 7px;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

.super-admin-event-month-picker {
  ::v-deep .date-popover {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    min-width: 280px;
  }

  ::v-deep .month-picker-wrapper {
    min-width: 100px !important;
  }

  ::v-deep .month-year-display {
    height: calc(1.5em + 1.25rem + 2px);
    color: #606266;
    font-size: 14px;
    font-weight: 400;
  }
}

</style>

<style lang="scss">
.event-list-table {
  tr {
    &.before-date {
      background-color: #e9ecef8f;

      &:hover {
        background-color: #e9ecef8f;
      }
    }
  }
}
</style>
